import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import seo from "../images/palmomedia-seo.jpg";
import blue from "../images/palmomedia-blue.jpg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Web- & APP- Gestaltung und Programmierung" />
      <h1>SEO &amp; DevOps</h1>
      <p>
        Wie jetzt? Das fragen Sie sich gerade?! Wie gehören denn jetzt
        Suchmaschinenoptimierung und DevOps (Development (englisch für
        Entwicklung) und IT Operations) zusammen?
      </p>
      <img src={seo} alt="seo" className="images" />
      <br />
      <br />
      <p>
        Ganz klar, wir erstellen <b>leichtgewichtige</b> schnelle Webseiten oder
        APPs. Agil zusammen mit Ihnen maßgeschneidert auf Ihr Produkt.
        <b>Time-to-market</b> ist hierbei für uns ein wesentlicher Punkt. Dabei
        ist die <b>SEO-Strategie</b> immer ein Bestandteil der ganzheitlichen
        Betrachtung ihres Erfolgs. Wir begleiten Sie dabei, die passenden Google
        AdWords zu finden und eine gute Basis für Ihre Marktplatzierung zu
        kreieren.
      </p>
      <img src={blue} alt="blue" className="images" />
      <h2>DevOps</h2>
      <p>
        Vom ersten Gespräch. Über die Iteration 0 bis Sprint n. Von der
        Programmierung, der Gestaltung bis zum Rollout Ihrer eigenen Software.
        Von der Idee der APP bis zum Launch. Wir verstehen uns als die
        Möglichmacher und wir begleiten Sie die ganze Strecke.{" "}
        <i>Ihr Weg ist unsere Mission</i>. Das verstehen WIR unter DevOps.
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
